import React, { useState, useEffect } from "react";
import { Component } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import {
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { Contract, BrowserProvider, parseEther, parseUnits, verifyMessage, hexlify, toUtf8Bytes } from "ethers";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import DocumentMeta from "react-document-meta";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import LeftTab from "../Common/LeftTab";
import { withTranslation } from 'react-i18next';
import { useTranslation } from "react-i18next";
import nacl from 'tweetnacl';

import { Client, Wallet } from 'xrpl';
// import TronWeb from "tronweb";

import {
  cryptoCoinList,
  deleteWhiteList,
  userData,
  whiteListAdd,
  whiteListData,
} from "../services/WhiteListServices";

import { bscRpcUrl, binanceChainId, explorerUrlBsc, ethChainId, explorerUrlEth, EthRpcUrl, polygonChainId, test_polygonChainId, explorerUrlPolygon, polygonRpcUrl, test_polygonRpcUrl, avalancheChainId, explorerUrlAvalanche, avalancheRpcUrl, projectId, bsc_ta_usdt, test_binanceChainId, test_bscRpcUrl, test_explorerUrlBsc } from "../../utils/enum";

const baseUrl = myConstList.baseUrl;
const meta = {
  title: "Whitelist | DBI  Exchange ",
  description:
    "DBI  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://DBI  Exchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

export const WhiteList = () => {
  const { t } = useTranslation();
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [state, setState] = useState({ referal_code: "", short_name: "", coinType: "", name: "" });
  const [stateError, setStateError] = useState({});
  const [tableData, setTableData] = useState({});
  const [cryptoData, setCryptoData] = useState({});
  const [walletConnectedtron, setWalletConnectedtron] = useState(false);
  const [walletConnectedsol, setWalletConnectedsol] = useState(false);
  const [addresstron, setAddresstron] = useState(null);
  const [client, setClient] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [balance, setBalance] = useState(null);
  const [walletList, setWalletList] = useState([{ short_name: "BNB", id: 1 }, { short_name: "SOL", id: 6 }, { short_name: "TRX", id: 3 }]);


  const [chainType, setChainType] = useState("")

  const mainnet = [
    {
      chainId: (chainType == "test_") ? test_binanceChainId : binanceChainId,
      name: "Binance Smart Chain",
      currency: "BNB",
      explorerUrl: (chainType == "test_") ? explorerUrlBsc : test_explorerUrlBsc,
      rpcUrl: (chainType == "test_") ? test_bscRpcUrl : bscRpcUrl,
    },
    {
      chainId: ethChainId,
      name: "Ethereum Mainnet",
      currency: "ETH",
      explorerUrl: explorerUrlEth,
      rpcUrl: EthRpcUrl,
    },
    {
      chainId: (chainType == "test_") ? test_polygonChainId : polygonChainId,
      name: "Polygon",
      currency: "MATIC",
      explorerUrl: explorerUrlPolygon,
      rpcUrl: (chainType == "test_") ? test_polygonRpcUrl : polygonRpcUrl,
    },

    {
      chainId: avalancheChainId,
      name: "Avalanche",
      currency: "AVAX",
      explorerUrl: explorerUrlAvalanche,
      rpcUrl: avalancheRpcUrl,
    },
  ];

  const metadata = {
    name: "dbiclient",
    description: "dbi client",
    url: "https://dbiexchange.com/",
    icons: ["https://dbiexchange.com/assets/img/logo.png"],
  };

  const tronWeb = window.tronWeb;
  const provider = window.solana;
  useEffect(() => {


    console.log("TRX connect  = ", walletConnectedtron, tronWeb)
    if (tronWeb?.ready != undefined) {
      setWalletConnectedtron(tronWeb.ready)

    }
    ShowListData();
    CryptoCoinData();
    GetUserData();

    createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      chains: mainnet,
      projectId,
      enableAnalytics: true,
    });

  }, [])

  useEffect(() => {
    console.log(Object.keys(window));
    console.log(provider);
    if (provider) {
      provider.on("disconnect", () => {
        //  alert("Your wallet has been disconnected.");
        console.log("Wallet disconnected.");
      });

      // const soladdress = provider.publicKey.toBase58();
      if (provider.publicKey) {
        // alert("connected");
        // setWalletConnectedsol(true)
      }


    }
  }, [provider])

  // constructor(props) {
  //   super(props);
  //   state = {
  //     isModalOpen: false,
  //     BtnDisable: false,
  //     cryptoCheckedBoxes: [],
  //     modalIsOpen: false,
  //     Cryptodata: [],
  //   };
  //   afterOpenModal = afterOpenModal.bind(this);
  //   closeModal = closeModal.bind(this);
  //   GetUserData();
  // }



  const componentWillMount = () => {

  };

  const AddWhitelist = () => {
    setState({ isModalOpen: true });
  };

  const closeModal1 = () => {
    setState({ isModalOpen: false });
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    // console.log(name, value );

    setStateError({ errMsg: "", coinTypeError: "" });
    if (name === "coinType") {

      if (value === "") {
        setState({ ...state, [name]: value, short_name: "" });
        setStateError({
          coinTypeError: "This field is required",
        });
        return false;
      } else {
        let coin = walletList.find((val) => val.id == value)
        setState({ ...state, [name]: value, short_name: coin.short_name });
      }

      setStateError({
        coinTypeError: "",
      });
    }
    //  else if (name === "whitelistAddress") {
    //     if (value === "") {
    //       setStateError({
    //         walletError: "This field is required",
    //       });
    //       return false;
    //     }
    //     if (!(value.length >= 26 && value.length <= 42)) {
    //       setStateError({
    //         walletError: "Please enter a valid wallet address",
    //       });
    //       return false;
    //     }

    //     setStateError({
    //       walletError: "",
    //     });
    //   }
    else if (name === "name") {
      setState({ ...state, [name]: value });
      if (value === "") {
        setStateError({
          nameError: "This field is required",
        });
        return false;
      }

      setStateError({
        nameError: "",
      });
    }
  };

  const onBlurCoinType = (event) => {
    var coinList = event.target.value;
    if (!coinList) {
      toast.dismiss();
      return false;
      // toast.error("Select Coin Type");
    }
    if (tableData.TableData) {
      for (let i = 0; i <= tableData.TableData.length - 1; i++) {
        if (tableData.TableData[i].coinType == state.coinType) {
          console.log(
            "insiderif",
            tableData.TableData[i].coinType,
            state.coinType
          );
          if (
            tableData.TableData[i].whitelistAddress ==
            state.whitelistAddress
          ) {
            // setState({ walletError: "Wallet address already exist" });
            //toast.error("Wallet address already exist")
            return false;
          } else {
            //  setState({ walletError: "" });
          }
        } else {
          // setState({ walletError: "" });
        }
      }
    }
  };

  const onBlurName = (event) => {
    var name = event.target.value;
    if (!name) {
      setState({ nameError: "Name filed is required" });
      return false;
    }
    if (name.replace(/\s+/g, "").length == 0) {
      setState({
        nameError: "Please enter a valid name",
      });
      return false;
    }
    if (name.length > 30) {
      setState({
        nameError: "Keyword must not exceed 30 characters",
      });
      return false;
    }
    setState({
      nameError: "",
    });
  };

  const onBlurWhitelistAddress = (event) => {
    var address = event.target.value;
    if (!address) {
      setState({
        walletError: "Wallet address is required",
      });
      return false;
      // toast.error("Enter Wallet Address");
    }
    if (address.includes(" ")) {
      setState({
        walletError: "Please enter a valid wallet address",
      });
      return false;
    }
    // if (address.replace(/\s+/g, '').length == 0) {
    //   setState({
    //     walletError:"Please enter a valid wallet address"
    //   })
    //   return false
    // }
    // if (address.length <= 19 || address.length >= 36) {
    //   setState({
    //     walletError: "Please enter a valid wallet address"
    //   })
    //   return false
    // }
    setState({
      walletError: "",
    });
  };

  const CryptoCoinData = async () => {
    let result = await cryptoCoinList();
    // axios.get(baseUrl+"/frontapi/cryptocoin_list").then((res)=>{
    var resp = result.data;
    setCryptoData({ CryptoStatus: result.status, Cryptodata: resp });
    // })
    // if(result.status){

    // }
  };

  const CryptoCoinDataHtml = () => {
    //const { t } = props;
    // console.log("cryptocoinlist",state.Cryptodata)
    if (cryptoData.CryptoStatus === true) {
      const html = [];
      //const classThis = this;
      html.push(<option value="">{t("Select")}</option>);
      // cryptoData.Cryptodata
      walletList.map(function (value, i) {
        html.push(<option value={value.id}>{value.short_name}</option>);
      });

      return (
        <select
          className="form-control"
          name="coinType"
          value={state.coinType}
          onChange={handleChange}
        // onBlur={onBlurCoinType}
        >
          {html}
        </select>
      );
    }
  };

  //  Disable = (address_Id,addressStatus) => {
  //     var address_Id = address_Id;
  //     var addressStatus=addressStatus;
  //         if(addressStatus==="0")
  //      {
  //          addressStatus="1";

  //      }
  //      else if(addressStatus==="1")
  //      {

  //          addressStatus="0";

  //      }
  //     let headerData = {
  //         headers : {'x-access-token':localStorage.getItem('token')}
  //       };

  //       var offer={
  //      address_Id:address_Id,
  //       status:addressStatus,

  //       }

  //        axios.post(baseUrl + '/frontapi/users/addressStatusUpdate',offer, headerData).then((resp) => {
  //         var resp = resp.data;
  //        //  console.log("resf",resp.data);
  //               if(resp.status===true) {
  //                   {getBuyOfferListdata()};
  //               }
  //         });
  //     }

  const ChangeStatus = (address_Id, addressStatus) => {
    var address_Id = address_Id;
    var addressStatus = addressStatus;
    if (addressStatus === "0") {
      addressStatus = "1";
    } else if (addressStatus === "1") {
      addressStatus = "0";
    }

    let headerData = {
      headers: { "x-access-token": localStorage.getItem("token") },
    };

    var offer = {
      address_Id: address_Id,
      status: addressStatus,
    };

    axios
      .post(baseUrl + "/frontapi/users/addressStatusUpdate", offer, headerData)
      .then((resp) => {
        var resp = resp.data;

        if (resp.status === true) {
          {
            // getBuyOfferListdata();
          }
        }
      });
  };

  const GetUserData1 = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-access-token": localStorage.getItem("token"),
      },
    };
    let result = userData();
    var resp = result.data;
    if (!localStorage.getItem("token")) {
      setState({
        kycVerification_status: "Y",
      });
      return false;
    }
    if (localStorage.getItem("token")) {
      setState({
        kycStatus: resp.data[0].kycVerification_status,
      });
    }

    if (resp.data[0].kycVerification_status === "Y") {
      setState({ modalIsOpen: false });
    }
    if (resp.data[0].kycVerification_status === "N") {
      setState({ modalIsOpen: true });
    }
  };

  const connectWallet = async () => {
    console.log("connect", chainId, address, state);
    try {

      if (state.coinType == undefined || state.coinType == "true") {
        console.log("coinType")
        setStateError({
          coinTypeError: "This field is required",
        });
        return false;
      }
      if (!state.name) {
        setStateError({
          nameError: "This field is required",
        });
        return false;
      }

      if (address) {
        setState({ ...state, whitelistAddress: address })
      }
      // let message = "";
      console.log("window=> ", window.ethereum);
      if ([1, 21, 7, 9, 11, 12, 13, 16, 17, 18, 19, 21, 34, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 10, 2, 50, 51].includes(parseInt(state.coinType))) {
        if (!window.ethereum) {
          toast.error("No crypto wallet found. Please install it.");
          //throw new Error("No crypto wallet found. Please install it.");
        }

        if (!address) {
          toast.error("No crypto wallet connected. Please connected it.");
          // throw new Error("No crypto wallet connected. Please connected it.");
        }

        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();



        const message = `I am signing this message to verify ownership of my wallet: ${address}`;
        // const encodedMessage = hexlify(toUtf8Bytes(message));

        const signature = await signer.signMessage(message);
        console.log("Signature:", signature);

        const recoveredAddress = verifyMessage(message, signature);

        const account = await signer.getAddress();

        console.log("Connected Wallet:", account);

        console.log(recoveredAddress);
        if (recoveredAddress == account) {
          console.log("Signature verified successfully!");
          handleSubmit(address)
        } else {
          console.error("Signature verification failed.");
        }
      } else if (state.coinType == "3") {
        //trx
        try {
          const rtxaddress = tronWeb.defaultAddress.base58;

          // Step 2: Create the message
          const originalMessage = `I am signing this message to verify ownership of my wallet: ${rtxaddress}`;
          console.log("Original Message:", originalMessage);

          // Step 3: Convert the message to hex for signing
          //  const hexMessage = tronWeb.toHex(originalMessage);
          const hexMessage = '0x' + Buffer.from(originalMessage, 'utf8').toString('hex');
          //tronWeb.utils.utf8ToHex(originalMessage);
          console.log("Hex Encoded Message:", hexMessage);

          // Step 4: Sign the hex-encoded message
          const signature = await tronWeb.trx.sign(hexMessage);
          console.log("Signature (Hex):", signature);

          // Step 5: Verify the signature

          tronWeb.trx.verifyMessage(hexMessage, signature, rtxaddress)
            .then(result => {
              console.log("result = ", result)
              handleSubmit(rtxaddress)
            })
            .catch(error => console.error(error));


          // const isValid = tronWeb.utils.crypto.verifySignature(
          //     hexMessage,   // Hex-encoded message
          //     signature,    // Signature (output of tronWeb.trx.sign)
          //     address       // Wallet address
          // );

          // console.log("Is signature valid?", isValid);

          // OPTIONAL: Decode the hex-encoded message back to readable format (for debugging)
          const decodedMessage = tronWeb.toUtf8(hexMessage);
          console.log("Decoded Message (Readable):", decodedMessage);
        } catch (e) {
          console.log(e)
          toast.error(e);
        }


      } else if (state.coinType == "6") {
        //sol
        try {

          if (!provider || !provider.isPhantom) {
            throw new Error("Phantom wallet not found. Please install it.");
          }

          // const response = await provider.connect();


          // const publicKey = response.publicKey;
          //await wallet.connect();

          // Get the public key of the connected wallet
          const soladdress = provider.publicKey.toBase58();
          const message = `I am signing this message to verify ownership of my wallet: ${soladdress}`;
          console.log("sol ", message);
          const encodedMessage = new TextEncoder().encode(message); // Encode to Uint8Array

          const signedMessage = await provider.signMessage(encodedMessage);
          const { signature, publicKey } = signedMessage;


          console.log("Connected Wallet Public Key:", publicKey.toString());



          // Step 2: Request the wallet to sign the message



          console.log("Signature (base58):", signature);
          console.log("Public Key:", publicKey.toBase58());

          // Step 3: Verify the signature
          const isVerified = nacl.sign.detached.verify(
            encodedMessage,         // Original message
            signature,              // The signature
            publicKey.toBytes()     // Public key as Uint8Array
          );
          console.log("isVerified: ", isVerified);

          if (isVerified) {
            handleSubmit(soladdress)
          }
          else {
            console.log("Failed to verify the message signature.");
          }
        } catch (error) {
          console.log("error: ", error);
          if (error instanceof Error) {
            if (error.message.includes('User rejected the request')) {
              console.error("User canceled the request.");
              toast.error("You canceled the request. Please try again if you want to proceed.");
            } else {
              console.error("An unexpected error occurred:", error.message);
              toast.error("Solana Wallet not connected, Please connect");
            }
          } else {
            console.error("An unknown error occurred:", error);
            toast.error("An unknown error occurred. Please try again.");
          }
        }

      } else if (state.coinType == "14") {
        let signature = "";
        let publicKey = "";
        let message = "";
        const result = Wallet.verifyMessage(message, signature, publicKey);
        console.log(result);
      }

      //  const provider = new ethers.providers.Web3Provider(window.ethereum);
      //     const accounts = await provider.send('eth_requestAccounts', []);
      //     const from = accounts[0]; // User's wallet address

      //     console.log('Connected account:', from);

      //     // Message to be signed
      //     const message = `I am signing this message to verify ownership of my wallet: ${from}`;
      //     const encodedMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message));

      //     // Request the signature
      //     const signature = await provider.provider.request({
      //       method: 'personal_sign',
      //       params: [encodedMessage, from],
      //     });

      //     console.log('Signature:', signature);

      //     // Optionally: Verify the signature (useful for backend validation)
      //     const signerAddress = ethers.utils.verifyMessage(message, signature);

      //     if (signerAddress.toLowerCase() === from.toLowerCase()) {
      //       console.log('Signature successfully verified!');
      //     } else {
      //       console.error('Signature verification failed.');
      //     }


      //  let contract = new Contract(
      //   data.coin_data[chainType + "contract_address"],
      //   // "0xd200828D90B2a88B6C3fb8554a308c28e68C4B13",
      //   nAbi,
      //   //  (chainType == "test_") ? test_abi:abi,
      //   // data.coin_data[chainType + "abi"],
      //   provider
      // );

      //  await window.ethereum.send("eth_requestAccounts");
      // const provider = new ethers.providers.Web3Provider(window.ethereum);
      // const signer = provider.getSigner();
      // const signature = await signer.signMessage(message);
      // const address = await signer.getAddress();
    } catch (e) {
      console.log(e);
      // if (e.info.error.code == 4001) {

      // }

    }
  }

  const connectToXRPLs = async () => {
    try {
      const xrplClient = new Client('wss://s1.ripple.com', { connectionTimeout: 10000 }); // Testnet URL
      let uu = await xrplClient.connect();
      setClient(xrplClient);
      console.log('Connected to XRPL! ', uu);
    } catch (error) {
      console.error('Failed to connect to XRPL:', error);
    }
  };

  const connectToXRPL = async () => {
    try {

      const accounts = await window.trustwallet.request({ method: 'eth_requestAccounts' });
      console.log('Connected to XRPL! ', accounts);
    } catch (error) {
      console.error('Failed to connect to XRPL:', error);
    }
  };

  const getBalance = async () => {
    if (!client || !wallet) return;
    try {
      const response = await client.request({
        command: 'account_info',
        account: wallet.classicAddress,
      });
      setBalance(response.result.account_data.Balance);
      console.log('Balance:', response.result.account_data.Balance);
    } catch (error) {
      console.error('Failed to fetch balance:', error);
    }
  };

  const handleSubmit = async (whitelistAddress) => {
    console.log("handlesubmit ", state.coinType, state.name, whitelistAddress);
    // if (
    //   stateError.coinTypeError ||
    //   stateError.nameError ||
    //   stateError.walletError
    // ) {
    //   return false;
    // }
    // if (state.coinType == undefined || state.coinType == "true") {
    //   setStateError({
    //     coinTypeError: "This field is required",
    //   });
    //   return false;
    // }
    // if (!state.name) {
    //   setStateError({
    //     nameError: "This field is required",
    //   });
    //   return false;
    // }
    // if (!state.whitelistAddress) {
    //   setStateError({
    //     walletError: "This field is required",
    //   });
    //   return false;
    // }

    if (tableData.TableData) {
      for (let i = 0; i <= tableData.TableData.length - 1; i++) {
        if (tableData.TableData[i].coinType == state.coinType) {
          console.log("insiderif", tableData.TableData[i].coinType, state.coinType);
          if (
            tableData.TableData[i].whitelistAddress == whitelistAddress) {
            // setStateError({ walletError: "Wallet address already exist" });
            toast.error("Wallet address already exist")
            return false;
          } else {
            // setState({ walletError: "" })
          }
        } else {
          // setState({ walletError: "" })
        }
      }
    }

    // setState({ BtnDisable: true });
    console.log("state.whitelistAddress.length", state);
    if (whitelistAddress.length < 26 || whitelistAddress.length > 50) {
      toast.error("Please enter a valid wallet address.")
      // setState({ walletError: "Please enter a valid wallet address" });
      // setState({ BtnDisable: false });
      return false;
    }
    if (state.name.length > 30 || state.name.length <= 1) {
      // toast.error("Please enter a valid wallet address.")
      setState({ ...state, nameError: "Please enter a valid name" });
      // setState({ BtnDisable: false });
      return false;
    }
    var walletAddress = {
      whitelistAddress: whitelistAddress,
      coinType: state.coinType,
      name: state.name,
      short_name: state.short_name
    };
    const token = localStorage.getItem("token");
    setState({ BtnDisable: true });
    let result = await whiteListAdd(walletAddress, token);
    console.log("dresultdqatatata", result);
    var resp = result.data;
    setState({
      msg: resp.message,
      BtnDisable: false,
      name: "",
      whitelistAddress: "",
      coinType: ""
    });
    if (resp.status === true) {
      // setState({
      //   isModalOpen: false,
      //   whitelistAddress: "",
      //   name: "",
      // });
      toast.success(resp.message);
    } else {
      toast.error(resp.message);
    }
    ShowListData();

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };

    // axios
    //   .post(baseUrl + "/frontapi/whitelist", walletAddress, config)
    //   .then((resp) => {
    //     var resp = resp.data;
    //     setState({
    //       msg: resp.message,
    //       BtnDisable: false,
    //       Name: "",
    //       whitelistAddress: "",
    //       coinType: "",
    //     });

    //     if (resp.status === true) {
    //       setState({
    //         isModalOpen: false,
    //         whitelistAddress: "",
    //         name: " ",
    //       });
    //       // toast.success(resp.message);
    //     } else {
    //       toast.error(resp.message);
    //     }
    //     ShowListData();
    //   });
  };

  const ShowListData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    var walletAddress = {
      userId: localStorage.getItem("id"),
    };
    let result = await whiteListData(walletAddress);
    var resp1 = result.data;
    console.log("rsp => ", resp1);
    //setState({... msg: resp1.message });
    if (resp1.status === true) {
      setTableData({ TableData: resp1.data, TableStatus: resp1.status });
    } else {
      setTableData({ TableData: [] });
    }
    console.log("tokentokentoken", result);

    return false;
    // axios
    //   .post(baseUrl + "/frontapi/whitelistData", walletAddress, config)
    //   .then((resp) => {
    //     var resp1 = resp.data;
    //     setState({ msg: resp.message });

    //     if (resp1.status === true) {
    //       setState({ TableData: resp1.data, TableStatus: resp1.status });
    //     } else {
    //       setState({ TableData: [] });
    //     }
    //   });
  };

  const whitelistDataHtml = () => {
    if (tableData.TableStatus === true) {
      const html = [];
      // const classThis = this;
      tableData.TableData.map(function (value, i) {
        html.push(
          <div className="whitelist-data">
            <div className="white-top d-flex justify-content-between">
              <div className="white-right-inner">
                <h5>{value.short_name}</h5>
              </div>
              <div className="white-left-inner">
                <h5>{value.name}</h5>
              </div>
            </div>
            <div className="white-bottom d-flex justify-content-between">
              <div className="white-left-inner mr-2">
                <h5>{value.whitelistAddress}</h5>
              </div>
              <div className="white-right-inner">
                <a
                  href="javascript:void(0)"
                  className="btn btn-danger"
                  onClick={() => DeleteWalletAddress(value)}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        );
      });

      return html;
    }
  };

  const checkCall = () => {

  }

  const DeleteWalletAddress = (value) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do ",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete_wallet_address(value),
        },
        {
          label: "No",
          onClick: () => checkCall,
        },
      ],
    });
  };

  const Delete_wallet_address = async (value) => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };

    let Payment_delete_Data = {
      whitelistAddress: value.whitelistAddress,
      name: value.name,
      userId: localStorage.getItem("id"),
    };

    let result = await deleteWhiteList(Payment_delete_Data);
    console.log("resultinsidedelte", result);
    if (result.data.status == true) {
      toast.dismiss();
      toast.success(result.data.message);
      ShowListData();
    }
  }


  function openModal() {
    setState({ modalIsOpen: true });
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    if (window.location.href === "http://172.105.119.53/kyc") {
      setState({ modalIsOpen: false });
    } else {
      setState({ modalIsOpen: true });
    }
  }
  const GetUserData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-access-token": localStorage.getItem("token"),
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;
      console.log("kycverification", resp.data[0]);
      if (resp.data[0]) {
        setState({
          kycStatus: resp.data[0].kycVerification_status,
          // addon from here
        });

        if (resp.data[0].kycVerification_status === "Y") {
          setState({ modalIsOpen: false });
        }
        if (resp.data[0].kycVerification_status === "N") {
          setState({ modalIsOpen: true });
        }
        if (resp.data[0].kycVerification_status === null) {
          setState({ modalIsOpen: true });
        }
      }
    });
  };

  const connectTrxWallet = async () => {
    console.log("TRX connect ", window.tronWeb, " = ")
    try {
      if (typeof window.tronWeb === 'undefined') {
        toast.error("TronLink wallet is not installed. Please install TronLink.");
        return;
      }


      let maxRetries = 100;
      while (!window.tronWeb.ready && maxRetries > 0) {
        await new Promise(resolve => setTimeout(resolve, 500)); // Wait 500ms
        maxRetries--;
      }

      if (!window.tronWeb.ready) {
        toast.error("TronLink is installed but not logged in. Please log in to TronLink.");

        return;
      }

      // TronLink is ready
      const tronWeb = window.tronWeb;
      const address = tronWeb.defaultAddress.base58;

      console.log(address, " = address");

      if (address) {
        setAddresstron(address);
        setWalletConnectedtron(true);

        // const balanceInSun = await tronWeb.trx.getBalance(address);
        // const balanceInTrx = tronWeb.fromSun(balanceInSun); // Convert from Sun (smallest unit) to TRX
        // setBalancetron(balanceInTrx);
        // console.log(balanceInTrx, "balanceTron");
        // if (balanceInTrx == 0) {
        //   toast("Tron insufficient balance.", {
        //     position: toast.POSITION.TOP_CENTER
        //   });
        // }

      } else {
        alert('TronLink is not connected to any account. Please connect your account in TronLink.');

      }
    } catch (e) {
      console.log(e);
    }
  };


  var showStatus = "";

  if (state.Whitelist_Status == 0) {
    showStatus = "Enable";
  } else if (state.Whitelist_Status == 1) {
    showStatus = "Disable";
  }

  return (
    <div className="">
      <DocumentMeta {...meta}>
        {/* <Modal show={state.isModalOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Address in Whitelist</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-3">
              <div className="row mb-3">
                <div className="col-sm-6">{t("Cryptocoin Name")}:</div>
                <div className="col-sm-6">{CryptoCoinDataHtml()}</div>
                <p className="errMsg text-center" style={{ color: "red" }}>
                  {t(stateError.coinTypeError)}
                </p>
              </div>

              <div className="row mb-3">
                <div className="col-sm-6">{t("Name")} :</div>
                <div className="col-sm-6">
                  <input
                    className="form-control"
                    placeholder="Enter Name"
                    minLength="2"
                    maxLength="30"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={state.name}
                  // onBlur={onBlurName}
                  />
                  <p className="errMsg text-center" style={{ color: "red" }}>
                    {stateError.nameError}
                  </p>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-sm-6">Wallet Address :</div>
                <div className="col-sm-6">
                  <input
                    className="form-control"
                    placeholder="Wallet Address"
                    type="text"
                    minLength="24"
                    maxLength="31"
                    name="whitelistAddress"
                    onChange={handleChange}
                    value={state.whitelistAddress}
                  // onBlur={onBlurWhitelistAddress}
                  />
                  <p className="errMsg text-center" style={{ color: "red" }}>
                    {t(stateError.walletError)}
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className=" btn btn_man"
              onClick={handleSubmit}
              disabled={state.BtnDisable}
            >
              Add{" "}
            </Button>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}

        <div className="bghwhite new_inpage">
          <div className="container container2">
            <LeftTab />
            <div className="in_page">
              <h5>{t("Whitelist")}</h5>
              <div className="wishlist-main-outer">
                <div className="row">
                  <div className="col-md-4 mb-4">
                    <div className="wishlist-left">
                      {" "}
                      <div className="btn11">
                        <w3m-button balance="hide" />
                      </div>
                      <div className="mt-3 mb-3">
                        <WalletModalProvider>
                          <WalletMultiButton />
                        </WalletModalProvider>

                      </div>
                      <div>
                        {(walletConnectedtron) ? <button className="btn btn3 w100">TronLink Wallet Connected</button> :
                          <button className="btn btn3 w100" onClick={() => connectTrxWallet()}>Connect TronLink Wallet</button>
                        }
                      </div>
                      {/* <div>
                      <button onClick={connectToXRPL}>Connect to XRPL</button>
                      <button onClick={getBalance}>Create Wallet</button>
                      </div> */}

                      <p>
                        {t("Whitelist is an additional security feature designed to protect your funds. When you enable Whitelist, your Account will be able to withdraw only to whitelisted withdrawal wallet addresses.")}
                      </p>
                      <div className="wishlist-form">
                        <div className="form-group">
                          <lable className="mb-2 display_inline_blog">
                            {t("Cryptocoin Name")}
                          </lable>
                          {CryptoCoinDataHtml()}
                          <p className="errMsg mt-0" style={{ color: "red" }}>
                            {t(stateError.coinTypeError)}
                          </p>{" "}
                        </div>

                        <div className="form-group">
                          <lable className="mb-2 display_inline_blog">
                            {t("Name")}
                          </lable>
                          <input
                            className="form-control"
                            placeholder={t("Enter") + " " + t("Name")}
                            type="text"
                            name="name"
                            onChange={handleChange}
                            value={state.name}
                          // onBlur={onBlurName}
                          />
                          <p className="errMsg mt-0" style={{ color: "red" }}>
                            {t(stateError.nameError)}
                          </p>
                        </div>
                        <div className="form-group">
                          {/* <lable className="mb-2 display_inline_blog">
                            {t("Wallet Address")}
                          </lable> */}
                          {/* <input
                            className="form-control"
                            placeholder={t("Enter") + " " + t("Wallet Address")}
                            type="text"
                            minLength="42"
                            maxLength="42"
                            onKeyPress={(event) => {
                              if (!/[a-zA-Z0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            name="whitelistAddress"
                            onChange={handleChange}
                            value={state.whitelistAddress}
                          // onBlur={onBlurWhitelistAddress}
                          /> */}
                          {/* <p className="errMsg mt-0" style={{ color: "red" }}>
                            {state.whitelistAddress}
                          </p> */}
                        </div>
                        <div>
                          <Button
                            className="btn btn_man"
                            onClick={connectWallet}
                            disabled={state.BtnDisable}
                          >
                            {t("Add")}{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="whitelist_scrool whilist-right-main">
                      {whitelistDataHtml()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Modal1
          id={"add-user-modal"}
          isOpen={state.modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          <img src="assets/img/v_kyc.png" />
          <h2 ref={(subtitle) => (subtitle = subtitle)}>
            Your KYC is not done. Kindly complete your KYC.{" "}
          </h2>

          <div>
            <Link className="btn btn_man mt-4 w100px" to="/kyc">
              Okay!
            </Link>
          </div>
        </Modal1> */}
      </DocumentMeta>
    </div>
  );

}

export default WhiteList;
