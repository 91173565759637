import React, { useState, useEffect } from 'react'
import Personal from './Personal'
import DocumentMeta from "react-document-meta";
import LeftTab from "../Common/LeftTab";
import axios from "axios";
import * as myConstList from "../Common/BaseUrl";
import { ToastContainer } from "react-toastify";
import LoginHeader from '../Common/LoginHeader';
import Aadhaar from './Aadhaar';
import Pancard from './Pancard';
import Passport from './Passport';
import Bank from './Bank';
import Upi from './Upi';
import Face from './Face';
import { ProductName } from '../Common/ProductName';
import SelfieClick from '../SettingsFile/SelfieClick';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const baseUrl = myConstList.baseUrl;
const meta = {
    title: 'KYC | DBI  Exchange ',
    description: "DBI  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
    canonical: 'https://DBI  Exchange.live/buy-sell-crypto-online',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange'
      }
    }
  };
const Index = () => {
    const [userdata, setUserdata] = useState([])
    const [countryCode, setCountryCode] = useState("")
    const [personalStatus, setPersonalStatus] = useState("0")
    const [aadhaarStatus, setAadhaarStatus] = useState("0")
    const [panStatus, setPanStatus] = useState("0")
    const [passportStatus, setPassportStatus] = useState("0")
    const [selfieStatus, setSelfieStatus] = useState("0")
    const [bankStatus, setBankStatus] = useState("0")
    const [upiStatus, setUpiStatus] = useState("0")
    const [classStatus, setClassStatus] = useState([])
 
    
    useEffect(() => {
       
        GetUserData()
        // meta.title =  `Kyc | ${ProductName} Exchange`,
        // meta.description =`Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`
          
    }, [])

    const GetUserData = () => {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
            var resp = resp.data.data;
            if (resp.length > 0) {
                setClassStatus([{
                    personal: resp[0].pi_status,
                    aadhaar: resp[0].identity_status == "2" ? "2" : "0",
                    pan: resp[0].image3_status,
                    passport: resp[0].passport_status,
                    selfie: resp[0].selfie_status,
                    bank: resp[0].bank_status,
                    upi: resp[0].upi ? "2" : "0"
                }])
                setCountryCode(resp[0].country_code)
                setUserdata(resp)
                setPersonalStatus(resp[0].pi_status)
                setPanStatus(resp[0].image3_status)
                setPassportStatus(resp[0].passport_status)
                setSelfieStatus(resp[0].selfie_status)
                setBankStatus(resp[0].bank_status)
                setAadhaarStatus(resp[0].identity_status)
                if (resp[0].upi) {
                    setUpiStatus(resp[0].upi_status)
                }
            }
        })
    }
    const resendUser = (e) => {
        GetUserData()
    }
    
    
    return (
        <div>
            <div className="bghwhite">
                <LoginHeader id={"allow"} />
                <DocumentMeta {...meta}>
                    <div className="kyc-main">
                        <div className="bghwhite">
                            <div className="new_inpage">
                                <div className="container container2">
                                    <LeftTab />
                                    {
                                       // console.log("statusttatsus", upiStatus ==="1",countryCode)
                                    }
                                 
                                    <Personal userinfo={userdata} resendResult={resendUser} status={classStatus} />
                                    {/* {personalStatus !== "0" && <Bank userinfo={userdata} resendResult={resendUser} />} */}
                                    {/* {personalStatus !== "0" && bankStatus !== "0" && <Upi userinfo={userdata} resendResult={resendUser} />} */}
                                    {/* {countryCode == "+91" && upiStatus === "1" && <Aadhaar userinfo={userdata} resendResult={resendUser} />}
                                    {countryCode == "+91" && aadhaarStatus !== "0" && <Pancard userinfo={userdata} resendResult={resendUser} />}
                                    {countryCode !== "+91" && upiStatus !== "0" && <Passport userinfo={userdata} resendResult={resendUser} />}
                                    {personalStatus !== "0" && <Passport userinfo={userdata} resendResult={resendUser} />}
                                    {(passportStatus !== "0" || panStatus !== "0") && <Face userinfo={userdata} resendResult={resendUser} />} */}
                                    {/* {(passportStatus !== "0" && personalStatus !== "0") && <Face userinfo={userdata} resendResult={resendUser} />} */}
                            
                                </div>
                            </div>
                        </div>
                    </div>
                </DocumentMeta>
            </div>
            <ToastContainer autoClose={2000} limit={1} />
        </div>
    )
}

export default Index