import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { clusterApiUrl } from '@solana/web3.js';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { Modal, Button } from "react-bootstrap"; // Import Bootstrap Modal
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

import Index from "./components/Homepage/Index";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Account from "./components/Account/Account";
import Security from "./components/SettingsFile/Security";
import Kyc from "./components/Kyc/index";
import Forgot from "./components/Login/Forgot";
import ForgotPin from "./components/Login/LoginElement/ForgotPin";
import ResetPassword from "./components/Login/LoginElement/ResetPassword";
import ApiKeys from "./components/SettingsFile/ApiKeys";
import help from "./components/help";
import chatting from "./components/chatting";
import ContactUs from "./components/ContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import RiskStatement from "./components/RiskStatement";
import TermsOfConditions from "./components/TermsOfConditions";
import AboutUs from "./components/AboutUs";
import faq from "./components/faq";
import { history } from "./helpers/history";
import NotFound from "./components/NotFound";
import transactions from "./components/SettingsFile/transactions";
import trade from "./components/SettingsFile/trade";
import Auth from "./components/Login/Auth";
import EmailAuth from "./components/Login/EmailAuth";
import CoinWithdrawalUsers from "./components/SettingsFile/CoinWithdrawalUser";
import MobileChart from "./components/SettingsFile/MobileChart";
import SpotSell from "./components/SettingsFile/SpotSell";
import DepositeHistory from "./components/SettingsFile/DepositeHistory";
import CoinDepositeUser from "./components/SettingsFile/CoinDepositeUser";
import Swap from "./components/SettingsFile/Swap";
import Profile from "./components/SettingsFile/Profile";
import Send from "./components/Send";
import whiteList from "./components/WhiteList/index";
import ExchnageFunc from "./components/ExchangeFull/ExchngeFunc";
import ExchangeSocket from "./components/ExchangeFull/ExchangeSocket";
import ExchangePair from "./components/ExchangeFull/ExchangePair";
import ExchangeMarket from "./components/ExchangeFull/ExchangeMarket";
import Blog from "./components/blog/Blog";
import BlogDetail from "./components/blog/BlogDetail";
import RejectVerification from "./components/verifypages/RejectVerification";
import ApproveVerification from "./components/verifypages/ApproveVerification";
import ApprovePayment from "./components/verifypages/ApprovePayment";
import CoinExchange from "./components/CoinExchange";
import MarketToken from "./components/SettingsFile/MarketToken";

import { baseUrl } from "./components/Common/BaseUrl";
import '@solana/wallet-adapter-react-ui/styles.css'; // Import wallet styles

const network = clusterApiUrl('devnet');
const wallets = [new PhantomWalletAdapter()];

const App = () => {
  const [showBannerModal, setShowBannerModal] = useState(false); // State to control modal visibility
  const [isLoginActive, setIsLoginActive] = useState("0");
  const [isMobile, setIsMobile] = useState(false); // State to detect if the device is mobile

  useEffect(() => {
    var token = localStorage.getItem("token");
    if (token) {
      // getData();
      if (
        window.location.pathname === "/register-for-bitcoin-exchange" ||
        window.location.pathname === "/login-to-buy-sell-crypto" ||
        window.location.pathname === "/forgot"
      ) {
        window.location.href = "/";
      }
    }

    // if (isLoginActive === "0") {
    //   setShowBannerModal(false);
    // }
  }, []);

  useEffect(() => {
    // Check screen width to determine if it's mobile
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true); // Mark as mobile
        setShowBannerModal(false); // Hide modal for mobile users
      } else {
        setIsMobile(false); // Mark as desktop
        setShowBannerModal(false); // Show modal for desktop users
      }
    };

    // Run the function on load and on window resize
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      //console.log(res.data);
      const resp = res.data;
      if (resp.status) {
        setIsLoginActive(resp.data[0].login_active);
      }
    });
  };

  const handleClose = () => setShowBannerModal(false);

  return (
    <ConnectionProvider endpoint={network}>
       <ToastContainer />
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <Router history={history}>
            <Switch>
              <Route exact path={["/", "/index"]} component={Index} />
              {/* {isMobile && (
          <Route
            path="*"
            render={() => {
              return <Redirect to="/" />;
            }}
          />
        )} */}
              <Route exact path="/login-to-buy-sell-crypto" component={Login} />
              <Route path="/register-for-bitcoin-exchange/:id" component={Register} />
              <Route path="/register-for-bitcoin-exchange" component={Register} />
              <Route exact path="/" component={Index} />
              <Route path="/account" component={Account} />
              <Route path="/whitelist" component={whiteList} />
              <Route path="/security" component={Security} />
              <Route path="/kyc" component={Kyc} />
              <Route path="/forgot" component={Forgot} />
              <Route path="/forgot-pin" component={ForgotPin} />
              <Route path="/reset/:userId/:token" component={ResetPassword} />
              <Route path="/ApiKeys" component={ApiKeys} />
              <Route path="/help" component={help} />
              <Route path="/faq-for-crypto-exchange" component={faq} />
              <Route path="/Chatting/:coin/:status" component={chatting} />
              <Route path="/contact-us-for-crypto-exchange" component={ContactUs} />
              <Route
                path="/secure-crypto-exchange-platform"
                component={PrivacyPolicy}
              />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/risk-statements" component={RiskStatement} />
              <Route
                path="/terms-&-conditions-of-crypto-exchange"
                component={TermsOfConditions}
              />
              <Route path="/terms-and-conditions" component={TermsOfConditions} />
              <Route path="/buy-sell-crypto-online" component={AboutUs} />
              <Route
                path="/exchange/:firstCoin/:secondCoin"
                component={ExchangeSocket}
              />
              <Route
                path="/listing/:firstCoin/:secondCoin"
                component={ExchangePair}
              />
              <Route
                path="/exchange-market/:firstCoin/:secondCoin"
                component={ExchangeMarket}
              />
              <Route path="/exchange/" component={ExchnageFunc} />
              <Route path="/transactions" component={transactions} />
              <Route path="/coinExchange" component={CoinExchange} />
              <Route path="/sale-market" component={MarketToken} />
              <Route path="/trade" component={trade} />
              <Route path="/coinFundRequest" component={CoinWithdrawalUsers} />
              <Route path="/GoogleAuthLogin" component={Auth} />
              <Route path="/Swap" component={Swap} />
              <Route path="/emailAuth" component={EmailAuth} />
              <Route
                path="/MobileChart/:firstCoin/:secondCoin"
                component={MobileChart}
              />
              <Route path="/spotSellHistory" component={SpotSell} />
              <Route path="/depositeHistory" component={DepositeHistory} />
              <Route path="/coinDeposite" component={CoinDepositeUser} />
              {/* <Route path="/refer" component={Refer} /> */}
              <Route path={"/profile"} component={Profile} />
              <Route path={"/payment"} component={Send} />
              <Route path={"/blog"} component={Blog} />
              <Route path={"/blog-detail/:id"} component={BlogDetail} />
              <Route
                path={"/rejectVerification/:id"}
                component={RejectVerification}
              />
              <Route
                path={"/approveVerification/:id"}
                component={ApproveVerification}
              />
              <Route path={"/approvePayment/:id"} component={ApprovePayment} />
              <Route path="*" exact={true} component={NotFound} />
            </Switch>

            {isMobile && (
              <Modal
                show={showBannerModal}
                onHide={handleClose}
                backdrop="static" // Prevent closing by clicking outside
                keyboard={false} // Prevent closing with "Escape" key
                size="lg"
                fullscreen={true}
              >
                {/* <Modal.Header>
          <Modal.Title>Important Announcement</Modal.Title>
        </Modal.Header> */}
                <Modal.Body>
                  <div className="image_popup">
                    <img
                      src="/assets/img/launching-banner.png"
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </Modal.Body>
                {/* <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            I Understand
          </Button>
        </Modal.Footer> */}
              </Modal>
            )}
          </Router>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
