import React from 'react'
import { useTranslation } from "react-i18next";
import { imgUrl } from "../../Common/BaseUrl";

const NeedHelp = (props) => {
  //console.log('Need help props---->', props.frontData);
  const { t } = useTranslation();
  return (
    <div className="help-main-outer">
      <div className='container'>

        <div className='help-outer'>
          <div className='row align-items-center'>
            <div className='col-md-6 col-12 text-center'>
              <img 
                // src='/assets/img/coose.png' 
                src={imgUrl+'/static/front/'+props.frontData[2]?.image}
                alt='' className='img-fluid' />
            </div>
            <div className='col-md-6 col-12 mt-4 mt-md-0'>

              <div className="section-heading ">
                {/* <h2>{t("Why Choose DBI!")}</h2> */}
                <h2 dangerouslySetInnerHTML={{__html: props.frontData[2]?.title}}></h2>
                <p dangerouslySetInnerHTML={{__html: props.frontData[2]?.description}}></p>
                {/* <p>{t("Most virtual asset users' assets are traded in their currency on exc. This is because only then can you withdraw it in cash. Every time there is news of an exc going bankrupt around the world, currency investors suffer. currency sent to the exc is stored in the exc coin wallet, and members may not be able to get their currency back if the exc closes.")}</p>
                <p>
                  {t("Therefore, currency investors make an important decision to choose a currency well and invest in it, but there is also the problem of choosing an exc well and making a decision to trade.")}</p>
                <p>
                  {t("In most exc, the coins on the exc are stored in a wallet and are shown as investment assets only in the whale's ledger. In order to protect these customer assets, DbiEXC displays them in the transaction ledger when a customer deposits currency assets into DbiEXC, and the customer assets are later transferred to the currency management bank. Thus, customers can take advantage of safe margin trading.")}</p>
                <p> {t("Even if the exc is closed, you can withdraw your assets from the bank where your currency is stored.")} </p> */}
              </div>
              <div className='row'>
                <div className='col-sm-6 col-12'>
                  <div className='help-inner d-flex flex-wrap  position-relative'>
                    <div className='img-outer'>
                      <i className="fa fa-check"></i>
                    </div>
                    <div className='content-outer'>
                      <b>{t("Safety Comes First")}</b>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 col-12'>
                  <div className='help-inner d-flex flex-wrap  position-relative'>
                    <div className='img-outer'>
                      <i className="fa fa-check"></i>
                    </div>
                    <div className='content-outer'>
                      <b>{t("Fast Transactions")}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-12'>
                  <div className='help-inner d-flex flex-wrap  position-relative'>
                    <div className='img-outer'>
                      <i className="fa fa-check"></i>
                    </div>
                    <div className='content-outer'>
                      <b>{t("Bonus & Refferal")}</b>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 col-12'>
                  <div className='help-inner d-flex flex-wrap  position-relative'>
                    <div className='img-outer'>
                      <i className="fa fa-check"></i>
                    </div>
                    <div className='content-outer'>
                      <b>{t("Easy Deposit & Withdrawals")}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-12'>
                  <div className='help-inner d-flex flex-wrap  position-relative'>
                    <div className='img-outer'>
                      <i className="fa fa-check"></i>
                    </div>
                    <div className='content-outer'>
                      <b>{t("Low Charges")}</b>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 col-12'>
                  <div className='help-inner d-flex flex-wrap  position-relative'>
                    <div className='img-outer'>
                      <i className="fa fa-check"></i>
                    </div>
                    <div className='content-outer'>
                      <b>{t("24/7 Support")}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default NeedHelp