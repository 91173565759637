export const bscRpcUrl = "https://bsc-dataseed.binance.org/";
export const test_bscRpcUrl = "https://data-seed-prebsc-2-s2.binance.org:8545/";
export const explorerUrlBsc = "https://bscscan.com/";
export const test_explorerUrlBsc = "https://bscscan.com/";
export const binanceChainId = 56;
export const test_binanceChainId = 97;
export const networkbsc="0x61"

export const EthRpcUrl = "https://eth-sepolia.g.alchemy.com/v2/pkhpwEgL2B0yCRvs4YZ_MIQFe9isWv3y";
export const explorerUrlEth = "https://etherscan.io/";
export const ethChainId = 11155111;
export const networketh="0xaa36a7"

export const polygonRpcUrl = "https://polygon-mainnet.g.alchemy.com/v2/j5G70U1alMQNyIDVJTcNH-wumaLwHmn-";
export const test_polygonRpcUrl = "https://rpc-amoy.polygon.technology/";
// export const polygonRpcUrl = "https://polygon-amoy.g.alchemy.com/v2/wy3C3ZExqNtP_Ff9QG9GYpvGVA6D-vnu";
export const explorerUrlPolygon = "https://amoy.polygonscan.com/";
export const polygonChainId = 137;
export const test_polygonChainId = 80002;
export const networkpoly="0x13882"

export const avalancheRpcUrl = `https://api.avax-test.network/ext/bc/C/rpc`;
export const avalancheChainId = 43113;
export const explorerUrlAvalanche = "https://snowtrace.io/";
export const networkavala="0xA869"

export const bsc_ta_usdt="0xf8d8D70fdb7D93F3DcedaB64aa413f025325f345"
export const projectId = "6c9760534aa3c822cb8a072339bbca59"