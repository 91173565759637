import React, { useEffect, useState, useRef } from "react";
import { EmailValidation } from "../Validation/EmailValidation";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as myConstList from "../Common/BaseUrl";
import { MobileApi, checkKycStatus } from "./Apis";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";


import { initKYC } from "./js/kyc";
import { syntaxHighlight, updateDebugWin, signIn } from './js/util';

const _ = require('lodash');

const baseUrl = myConstList.baseUrl;
const Personal = (props) => {
  const { t } = useTranslation();
  const [userdata, setUserData] = useState({});
  const [length, setLength] = useState(0);
  const [email, setEmail] = useState("");
  const [verification_code, setVerification_code] = useState("");
  const [counter, setCounter] = useState(0);
  const [timerText, setTimerText] = useState("");
  const [textForCode, setTextForCode] = useState("Get Code");
  const [emailError, setEmailError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [codeVerifyStatus, setCodeVerifyStatus] = useState(false);
  const [codeBtnDisable, setCodeBtnDisable] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [selectedFiledType, setSelectedFiledType] = useState("email");
  const [countryCode, setCountryCode] = useState("+");
  const [disableMobile, setDisableMobile] = useState(false);

  const myRef = useRef(null);
  const [kycUserData, setKycUserData] = useState({ name: "", birthday: "", phone_number: "", email: "" })
  const [userInfo, setUserInfo] = useState({ type: "use_input_ui", showIframe: false, iframeUrl: "https://kyc.useb.co.kr/auth" })

  const KYC_PARAMS = {
    105: { customer_id: '112', id: 'M1tU4XA4Va', key: 'PE4%m98YYNnC2fv' },
    3: { customer_id: '112', id: 'M1tU4XA4Va', key: 'PE4%m98YYNnC2fv' },
  };
  let url = {
    "params": "https://kyc.useb.co.kr",
    "use_input_ui": "https://kyc.useb.co.kr/auth"
  }
  let KYC_TARGET_ORIGIN = 'https://kyc.useb.co.kr';
  let KYC_URL = 'https://kyc.useb.co.kr/auth';

  const handler = (e) => {
    setKycUserData({ ...kycUserData, [e.target.name]: e.target.value })
  }

  const selectPrivacy = (type) => {
    setUserInfo({ ...userInfo, type, iframeUrl: url[type] })
  }

  useEffect(() => {

    // const decodedData = decodeURIComponent(atob("JTdCJTIycmVzdWx0JTIyJTNBJTIwJTIyY2xvc2UlMjIlN0Q="));
    // console.log('decoded', decodedData);
    //  const json = JSON.parse(decodedData);
    // console.log("call ",json);
    // if (userInfo.showIframe) {
    window.addEventListener('message', (e) => {
      // console.log('alcherakyc response', e.data); // base64 encoded된 JSON 메시지이므로 decoded해야 함
      // console.log('origin :', e.origin);
      try {
        if (e.data && e.origin == "https://kyc.useb.co.kr") {
          const decodedData = decodeURIComponent(atob(e.data));
          // console.log('decoded', decodedData);
          const json = JSON.parse(decodedData);
          console.log(localStorage.getItem("id"), ' json ', json);

          // console.log(json.result + '처리 필요');

          if (localStorage.getItem("id")) {
            axios.post(baseUrl + "/frontapi/crate_kyc_log", { id: localStorage.getItem("id"), log: json, platform: "web" }).then((response) => {
              console.log("response: " + response.data.status);

              if (response.status == 200 && response.data.status == "success") {
                let tUser = userdata;
                tUser.kycVerification_status = "Y";
                console.log("tUser => ", tUser);
                setUserData(tUser);
              }
            }).catch((error) => {
              if (error.response) {
                console.log(error.response.data); // => the response payload 
              }
            });




          }
          if (json.result === "success") {
            // TODO: KYC 인증을 성공한 경우 데이터 처리
            // console.log(json.result + "결과 서버 저장");
          } else if (json.result === "failed") {
            // TODO: KYC 인증을 실패한 경우 데이터 처리
            // console.log(json.result + "결과 서버 저장");
          }

          // UI 처리
          else if (json.result === "complete") {

            // TODO: KYC 인증을 성공(자동승인 or 심사필요 케이스 모두 포함) 후 UI 처리
            // iframe이 포함된 UI를 종료
            // 고객사 서비스 UI를 다시 띄우고 상황에 맞는 UI 표시
            // 예시 : 자동승인 -> KYC인증이 완료되었습니다. 계좌개설이 완료되었습니다.
            // 예시 : 심사필요 -> KYC인증이 완료되었습니다. 심사가 완료된 이후 이메일로 안내 드리겠습니다.
          } else if (json.result === "close") {
            // TODO: KYC 인증을 실패(자동거부 or 중도이탈) 후 UI 처리
            // iframe이 포함된 UI를 종료
            // 고객사 서비스 UI를 다시 띄우고 상황에 맞는 UI 표시
            // 예시 : 자동거부 -> KYC인증이 실패하였습니다. 다시 인증을 시도후 서비스 이용이 가능합니다.
          } else {
            // invalid result
          }

          let json2 = _.cloneDeep(json);
          if (json2 && json2.review_result && json2.review_result.id_card) {
            const review_result = json2 && json2.review_result;
            if (review_result.id_card) {
              const id_card = review_result.id_card;
              if (id_card.id_card_image) {
                id_card.id_card_image =
                  id_card.id_card_image.substring(0, 20) + '...생략...';
              }
              if (id_card.id_card_origin) {
                id_card.id_card_origin =
                  id_card.id_card_origin.substring(0, 20) + '...생략...';
              }
              if (id_card.id_crop_image) {
                id_card.id_crop_image =
                  id_card.id_crop_image.substring(0, 20) + '...생략...';
              }
            }

            if (review_result.face_check) {
              const face_check = review_result.face_check;
              if (face_check.selfie_image) {
                face_check.selfie_image =
                  face_check.selfie_image.substring(0, 20) + '...생략...';
              }
            }
          }

          if (json2 && json2.attachment) {
            const attachment = json2.attachment;
            for (const key in attachment) {
              if (attachment[key].id) {
                attachment[key].value =
                  attachment[key].value.substring(0, 20) + '...생략...';
              }
            }
          }

          const str = JSON.stringify(json2, undefined, 4);
          const strHighlight = syntaxHighlight(str);

          if (json.result === 'success') {
            // updateDebugWin(strHighlight);
            updateKYCResult(strHighlight, json);
          } else if (json.result === 'failed') {
            //  updateDebugWin(strHighlight);
            updateKYCResult(strHighlight, json);
          } else if (json.result === 'complete') {
            // updateDebugWin(strHighlight);
            updateKYCStatus('KYC가 완료되었습니다.');
            endKYC();
          } else if (json.result === 'close') {
            // updateDebugWin(strHighlight);
            updateKYCStatus('KYC가 완료되지 않았습니다.');
            endKYC();
          } else {
            // invalid result
          }
        }
      } catch (error) {
        console.log('wrong data', error);
      }
    });
    //}
  }, []);

  async function buttonOnClick(idx) {
    const kycIframe = document.getElementById('kyc_iframe');
    // console.log(kycIframe, " - ", idx,"  ",kycUserData," ",userInfo );

    kycIframe.onload = async function () {
      let params = _.cloneDeep(KYC_PARAMS[idx]);

      //    const wasmOCRModeCheckbox = document.getElementById(
      //      'wasm_ocr_mode_checkbox'
      //   );
      //    if (wasmOCRModeCheckbox.checked) {
      //      params = { ...params, isWasmOCRMode: 'true' };

      //      const wasmSSAModeCheckbox = document.getElementById(
      //        'wasm_ssa_mode_checkbox'
      //      );
      //      if (wasmSSAModeCheckbox.checked) {
      //        params = { ...params, isWasmSSAMode: 'true' };
      //      }
      //  }
      if (userInfo.type === 'params') {
        params.name = kycUserData.name;
        params.birthday = kycUserData.birthday;
        params.phone_number = kycUserData.phone_number
        params.email = kycUserData.email;

        params.customer_id = String(idx + 7);
        // console.log(params);
        if (
          params.name === "" ||
          !params.birthday === "" ||
          !params.phone_number === "" ||
          !params.email === ""
        ) {
          alert('필수 정보가 입력되지 않았습니다.');
          hideLoadingUI();
          return;
        }
      }

      const authType = document.getElementById('auth_type_checkbox');
      if (authType.checked) {
        const { token } = await signIn({
          customer_id: params.customer_id,
          username: params.id,
          password: params.key,
        });
        params = { ...params, access_token: token };
      }
      // console.log(params);
      //   const isEnglish = document.getElementById('is_english_checkbox');
      //   if (isEnglish.checked) {
      //     params = { ...params, language: 'en' };
      //   }

      //   const customFont = document.getElementById('custom_font');
      //   if (customFont?.value !== '') {
      //     params = { ...params, font: customFont.value };
      //   }

      let encodedParams = btoa(encodeURIComponent(JSON.stringify(params)));
      // console.log(encodedParams);
      kycIframe.contentWindow.postMessage(encodedParams, KYC_TARGET_ORIGIN);
      hideLoadingUI();
      startKYC();
      kycIframe.onload = null;
    };

    kycIframe.src = KYC_URL;
    //showLoadingUI();
    setUserInfo({ ...userInfo, showIframe: true })
  }

  function updateKYCStatus(msg) {
    const div = document.getElementById('kyc_status');
    div.innerHTML = msg;
  }

  function endKYC() {
    document.getElementById('customer_start_ui').style.display = 'none';
    document.getElementById('kyc').style.display = 'none';
    document.getElementById('customer_end_ui').style.display = 'block';
  }

  function updateKYCResult(data, json) {
    const imageConverter = function (str) {
      return 'data:image/jpeg;base64,' + str;
    };

    const kycResult = document.getElementById('kyc_result');
    kycResult.innerHTML = '';

    const title1 = document.createElement('h3');
    title1.innerHTML = '<h3 class="custom--headline">최종 결과</h3>';

    const result1 = document.createElement('div');
    result1.className = 'syntaxHighlight bright';
    result1.style.textAlign = 'center';

    const detail = json.review_result;
    let content = '';

    if (detail) {
      let result_type_txt = 'N/A';
      if (detail.result_type === 1) {
        result_type_txt = "<span style='color:blue'>자동승인</span>";
      } else if (detail.result_type === 2) {
        result_type_txt = "<span style='color:red'>자동거부</span>";
      } else if (detail.result_type === 5) {
        result_type_txt = "<span style='color:orange'>수동심사대상</span>";
      } else {
        result_type_txt = 'INVALID_TYPE';
      }
      title1.innerHTML +=
        '- 인증 결과 : ' +
        (json.result === 'success'
          ? "<span style='color:blue'>성공</span>"
          : "<span style='color:red'>실패</span>") +
        ' </br>';
      title1.innerHTML += '- 종합 판정 결과 : ' + result_type_txt + ' </br>';

      if (detail.module.id_card_ocr) {
        content =
          "<h5><span style='color:blue'>■ 정상</span> | <span style='color:red'>■ 거부사유</span> | <span style='color:orange'>■ 수동심사사유</span> | <span style='color:purple'>■ 참고사항</span></h5>";
        content += "<h4 class='subTitle'>신분증 인증 결과</h4>";
        content +=
          '<br/> - 정부기관 대조 결과 : ' +
          (detail.id_card && !detail.module.id_card_verification
            ? 'N/A'
            : detail.id_card.verified
              ? "<span style='color:blue'>성공</span>"
              : "<span style='color:red'>실패</span>");

        if (detail.id_card.modified !== undefined) {
          content +=
            '<br/> - 정보수정여부 : ' +
            (detail.id_card.modified === false
              ? "<span style='color:blue'>NO</span>"
              : "<span style='color:orange'>YES</span>");
        }

        if (detail.id_card.is_uploaded !== undefined) {
          content +=
            '<br/> - 신분증 제출방식 : ' +
            (detail.id_card.is_uploaded === false
              ? "<span style='color:blue'>카메라 촬영</span>"
              : "<span style='color:purple'>파일 업로드</span>");
        }

        if (detail.id_card.original_ocr_data) {
          try {
            const original_ocr_data = JSON.parse(
              detail.id_card.original_ocr_data
            );
            if (original_ocr_data.truth) {
              content +=
                '<br/> - 신분증 사본 판별 결과 : ' +
                (original_ocr_data.truth === 'REAL'
                  ? "<span style='color:blue'>REAL</span>"
                  : "<span style='color:purple'>FAKE</span>");
              content +=
                '<br/> - 신분증 사본 판별 Confidence : ' +
                (original_ocr_data.truth === 'REAL'
                  ? "<span style='color:blue'>" +
                  original_ocr_data.truthConfidence +
                  '</span>'
                  : "<span style='color:purple'>" +
                  original_ocr_data.truthConfidence +
                  '</span>');
            }
          } catch (e) {
            console.error('original_ocr_data JSON parse error : ' + e);
          }
        }

        if (detail.id_card.id_card_image) {
          content += '<br/>';
          content +=
            "<br/> - 신분증 마스킹 사진<br/>&nbsp;&nbsp;&nbsp;<img style='max-height:200px;' src='" +
            imageConverter(detail.id_card.id_card_image) +
            "' /></b>";
        }

        if (detail.id_card.id_card_origin) {
          content +=
            "<br/> - 신분증 원본 사진<br/>&nbsp;&nbsp;&nbsp;<img style='max-height:200px;' src='" +
            imageConverter(detail.id_card.id_card_origin) +
            "' /></b>";
        }

      }

      if (detail.module.face_authentication) {
        content += '<br/>';
        content +=
          "<h4 class='subTitle'>신분증 얼굴 사진 VS 셀피 사진 유사도</h4>";
        content +=
          '<br/> - 유사도 측정 결과 : ' +
          (detail.face_check
            ? detail.face_check.is_same_person
              ? "<span style='color:blue'>높음</span>"
              : "<span style='color:orange'>낮음</span>"
            : 'N/A');
        if (detail.face_check) {
          content +=
            "<br/> - 신분증 얼굴 사진<br/>&nbsp;&nbsp;&nbsp;<img style='max-height:100px;' src='" +
            imageConverter(detail.id_card.id_crop_image) +
            "' />";
          content +=
            "<br/> - 셀피 촬영 사진<br/>&nbsp;&nbsp;&nbsp;<img style='max-height:100px;' src='" +
            imageConverter(detail.face_check.selfie_image) +
            "' />";
        }
      }

      if (detail.module.account_verification) {
        content += '<br/>';
        content += "<h4 class='subTitle'>1원 계좌 인증</h4>";
        content +=
          '<br/> - 1원 계좌 인증 결과 : ' +
          (detail.account
            ? detail.account.verified
              ? "<span style='color:blue'>성공</span>"
              : "<span style='color:red'>실패</span>"
            : 'N/A');
        if (detail.account) {
          content +=
            '<br/> - 예금주명 : ' +
            (detail.account.account_holder
              ? detail.account.account_holder
              : 'N/A');
          content +=
            '<br/> - 수정된 예금주명(수정한 경우만) : ' +
            (detail.account.mod_account_holder
              ? "<span style='color:orange'>" +
              detail.account.mod_account_holder +
              '</span>'
              : 'N/A');
          content +=
            '<br/> - 금융사명 : ' +
            (detail.account.finance_company
              ? detail.account.finance_company
              : 'N/A');
          content +=
            '<br/> - 금융사코드 : ' +
            (detail.account.finance_code ? detail.account.finance_code : 'N/A');
          content +=
            '<br/> - 계좌번호 : ' +
            (detail.account.account_number
              ? detail.account.account_number
              : 'N/A');
        }
      }

      if (detail.module.liveness) {
        content += '<br/>';
        content += '<h4 class=\'subTitle\'>셀피 사진 진위확인</h4>';
        content +=
          '<br/> - 셀피(얼굴) 사진 진위확인(라이브니스) 결과 : ' +
          (detail.face_check
            ? detail.face_check.is_live
              ? '<span style=\'color:blue\'>성공</span>'
              : '<span style=\'color:red\'>실패</span>'
            : 'N/A');
      }
    }

    result1.innerHTML = content;
    kycResult.appendChild(title1);
    kycResult.appendChild(result1);

    const title2 = document.createElement('h3');
    title2.innerHTML = '<h3 class="custom--headline">PostMessage 상세</h3>';

    const result2 = document.createElement('pre');
    result2.className = 'syntaxHighlight bright';
    result2.innerHTML = data;
    kycResult.appendChild(title2);
    kycResult.appendChild(result2);
  }

  function hideLoadingUI() {
    document.getElementById('loading_ui').style.display = 'none';
  }

  function startKYC() {
    document.getElementById('customer_start_ui').style.display = 'none';
    document.getElementById('kyc').style.display = 'block';
    document.getElementById('customer_end_ui').style.display = 'none';
  }
  useEffect(() => {
    if (myRef.current) {
      // console.log(myRef);
      myRef.current.addEventListener("keyup", (e) => {
        if (!e.target) {
          return;
        }

        e.target.value = transformDateFormat(e.target.value, (e.key === 'Backspace'));
      });


      myRef.current.addEventListener("focusout", (e) => {
        if (!e.target) {
          return;
        }

        e.target.value = onblurTransformDateFormat(e.target.value);

        if (e.target.value !== '' && !validate_birthday(e)) {
          alert("생년월일 포멧(YYYY-MM-DD)이 올바르지 않습니다. (" + e.target.value + ")");
          e.target.value = "";
          /* setTimeout(() => {
              console.log('focus rollback');
              e.target.focus();
          }, 500); */

        }
      });
    }
  }, []);

  const transformDateFormat = (text, isBackspace) => {
    const testDateFormatRegx = /[^0-9-]/g;

    if (text) {
      text = text.replace(testDateFormatRegx, "");
    }
    if (!isBackspace && (/^\d{4}$/.test(text) || /^\d{4}-\d{2}$/.test(text))) {
      const checkMaxMonth = /^\d{4}-(\d{2})$/;
      if (checkMaxMonth.test(text) && checkMaxMonth.exec(text)[1] > 12) {
        text = text.slice(0, -1);
      } else {
        text += "-";
      }
    }
    if (!isBackspace && (/^\d{5}$/.test(text) || /^\d{4}-\d{3}$/.test(text))) {
      text = text.slice(0, -1) + "-" + text.slice(-1);
    }
    if (isBackspace && (/^\d{4}-$/.test(text) || /^\d{4}-\d{2}-$/.test(text))) {
      text = text.slice(0, -1);
    }
    const checkMaxDate = /^\d{4}-\d{2}-(\d{2})$/;
    if (
      !isBackspace &&
      checkMaxDate.test(text) &&
      checkMaxDate.exec(text)[1] > 31
    ) {
      text = text.slice(0, -1);
    }
    // check month
    const checkMonth = /^\d{4}-(\d{1})$/;
    if (!isBackspace && checkMonth.test(text)) {
      const result = checkMonth.exec(text);
      if (result[1] > 1) {
        text = text.slice(0, -1) + "0" + text.slice(-1);
      }
    }
    const checkMonthIsZero = /^\d{4}-(\d{2})-/;
    if (!isBackspace && checkMonthIsZero.test(text)) {
      const result = checkMonthIsZero.exec(text);
      if (result[1] === 0) {
        text = text.slice(0, -2);
      }
    }
    if (isBackspace && checkMonth.test(text)) {
      const result = checkMonth.exec(text);
      if (result[1] === 0) {
        text = text.slice(0, -2);
      }
    }
    // check date
    const checkDate = /^\d{4}-\d{2}-(\d{1})$/;
    if (!isBackspace && checkDate.test(text)) {
      const result = checkDate.exec(text);
      if (result[1] > 3) {
        text = text.slice(0, -1) + "0" + text.slice(-1);
      }
    }
    const checkDateIsZero = /^\d{4}-\d{2}-(\d{2})/;
    if (!isBackspace && checkDateIsZero.test(text)) {
      const result = checkDateIsZero.exec(text);
      if (result[1] == 0) {
        text = text.slice(0, -1);
      }
    }
    if (isBackspace && checkDate.test(text)) {
      const result = checkDate.exec(text);
      if (result[1] == 0) {
        text = text.slice(0, -2);
      }
    }
    if (text.length > 10) {
      text = text.slice(0, 10);
    }

    let dob1 = text.replace(/-/gi, "");
    let year = dob1.substr(0, 4) !== "" ? dob1.substr(0, 4) : null;
    let month = dob1.substr(4, 2) !== "" ? dob1.substr(4, 2) : null;
    let day = dob1.substr(6, 2) !== "" ? dob1.substr(6, 2) : null;

    let dob_item_list = [];
    if (year !== null) dob_item_list.push(year);
    if (month !== null) dob_item_list.push(month);
    if (day !== null) dob_item_list.push(day);

    text = dob_item_list.join('-');

    return text;
  };

  const onblurTransformDateFormat = (text) => {
    // in case of "1900-11-2" -> "focusout event is occurred" -> replace to "1900-11-02"
    if (text.length === 9 && text.substr(8, 1) != 0) {
      text = text.substr(0, 8) + "0" + text.substr(8, 1);
    }
    return text;
  };

  const validate_birthday = (e) => {
    let dob1 = e.target.value.replace(/-/gi, "");
    if (dob1.length !== 8) {
      return false;
    }
    const date = new Date(dob1.substr(0, 4), Number(dob1.substr(4, 2)) - 1, dob1.substr(6, 2));
    const today = new Date();
    if (today < date) return false;
    return (date.toString() !== "Invalid Date");
  };

  //console.log(localStorage.getItem("token"));

  const user = props.userinfo && props.userinfo[0];

  const isKycComplete = user?.selfie_status === "1" && user?.passport_status === "1";

  useEffect(() => {
    // console.log("dsfhvjsdfhsdgfsdf", props.userinfo);
    if (props.userinfo.length > 0) {
      setLength(props.userinfo.length);
      setUserData(props.userinfo[0]);
      // console.log(props.userinfo);
      // console.log( "props.userinfo[0].pi_status", typeof props.userinfo[0].pi_status, props.userinfo[0].pi_status == "2");
      if (props.userinfo[0].pi_status == "2") {
        setCodeBtnDisable(true);
      }
      setEmail(props.userinfo[0].email);
      setCountryCode(
        props.userinfo[0].country_code ? props.userinfo[0].country_code : "+"
      );
      setMobile(props.userinfo[0].mobile_no);
    }
  }, [props]);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    // console.log("propsorpsor");
    if (name == "email") {
      value = value.toLowerCase();
      setEmail(value);
      const emailvalidation = EmailValidation(name, value);
      setEmailError(emailvalidation);
      if (textForCode == "Resend Code") {
        setTextForCode("Get Code");
        setVerification_code("");
        // this.setState({textForCode:"Get Code"})
        // console.log("calling for error")
        // this.setState({VerificationCode:""})
      }
    }
    if (name == "verification_code") {
      setVerification_code(value);
      if (!value) {
        setCodeError("This field is required");
      } else {
        setCodeError("");
      }
    }
    if (name == "mobile") {
      // console.log("insidemobiler", name, value);
      setMobile(value);
      if (value === "") {
        setMobileError("This field is required");
        return false;
      }
      if (!(value.length >= 5 && value.length <= 15)) {
        setMobileError("Invalid mobile number");
        return false;
      }
      // const error = MobileValidation(name, value)
      setMobileError("");
    }
  };
  const startCountdown = (seconds) => {
    let counter = seconds;
    const interval = setInterval(() => {
      setCounter(counter);
      setCodeBtnDisable(true);
      setTimerText("Resend In");
      setDisableMobile(true);
      counter--;
      if (counter < 0) {
        clearInterval(interval);
        setTextForCode("Resend Code");
        setCodeBtnDisable(false);
        setTimerText("");
        setDisableMobile(false);
      }
    }, 1000);
  };
  const getCode = async () => {
    if (emailError) {
      return false;
    }
    const emailvalidation = EmailValidation("email", email);
    if (emailvalidation) {
      setEmailError(emailvalidation);
      return false;
    }

    let data = {
      email: email,
      page: "kyc",
    };
    await axios.post(baseUrl + "/frontapi/getcode", data).then((resp) => {
      let response = resp.data;
      if (response.status) {
        // setVerification_code(response.code)
        startCountdown(30);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    });
  };

  const getCodeMobile = async () => {
    if (mobileError) {
      return false;
    }

    const regex = /^\+\d{1,4}$/;

    if (!regex.test(countryCode)) {
      // setCountryCode("+");
      setMobileError("Please enter a valid country code");
      return;
    }

    if (!mobile) {
      setMobileError("This field is required");
      return;
    }

    if (mobile.length < 5 || mobile.length > 15) {
      setMobileError(
        "Mobile number should be atleast 5 and not more than 15 digits"
      );
      return;
    }

    let data = {
      mobile: mobile,
      page: "kyc",
      selectedFiledType: selectedFiledType,
      countryCode: countryCode,
    };
    await axios.post(baseUrl + "/frontapi/getcode", data).then((resp) => {
      let response = resp.data;
      if (response.status) {
        startCountdown(30);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    });
  };

  const handleSubmit = async (e) => {
    if (!email && !verification_code) {
      setEmailError("This field is required");
      setCodeError("This field is required");
      return false;
    }
    if (!email) {
      setEmailError("This field is required");
      return false;
    }
    if (!verification_code) {
      setCodeError("This field is required");
      return false;
    }
    if (emailError || codeError) {
      return false;
    }
    let data = {
      page: "kyc",
      email: email,
      mobile: mobile,
      verification_code: verification_code,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    setTimeout(() => {
      setBtnDisable(true);
    }, 50);
    await axios
      .post(baseUrl + "/frontapi/codeVerify", data, config)
      .then((resp) => {
        setBtnDisable(false);
        let response = resp.data;
        if (response.status) {
          toast.success(response.message);
          props.resendResult(true);
          setCodeVerifyStatus(true);
        } else {
          toast.error(response.message);
        }
      });
  };
  const handleMobileSubmit = async () => {
    if (!mobile) {
      setMobileError("This field is required");
      return false;
    }
    if (!(mobile.length >= 7 && mobile.length <= 15)) {
      setMobileError("Invalid mobile number");
      return false;
    }
    if (!verification_code) {
      setCodeError("This field is required");
      return false;
    }

    if (mobileError) {
      return false;
    }
    let data = {
      mobile: mobile,
      code: verification_code,
      countryCode: countryCode,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let result = await MobileApi(data, config);
    if (result.status) {
      toast.success(result.message);
      props.resendResult(true);
    } else {
      toast.error(result.message);
    }
  };

  const handleOnChange = (value, country) => {
    // You can access the selected country and formatted phone number here
    //console.log("Selected Country:", country);
    //console.log("Formatted Phone Number:", value);
    if (!value) {
      setSelectedFiledType("email");
    } else {
      setSelectedFiledType("mobile");
    }

    let adjustedMobile = value.startsWith(country.dialCode)
      ? value.replace(country.dialCode, "")
      : value;

    setMobile(adjustedMobile);
    setTextForCode("Get Code");
    setCountryCode("+" + country.dialCode);
    setMobileError("");
    setEmailError("");
  };

  const handleChangeMobile = (event) => {
    const { name, value } = event.target;

    setEmailError("");
    // setEmail("");
    setSelectedFiledType("mobile");
    if (name === "phone") {
      setMobile(value);

      if (!countryCode) {
        setMobileError("Country code is required");
        return;
      }

      if (!value) {
        setMobileError("This field is required");
        return;
      }

      if (value.length < 5 || value.length > 15) {
        setMobileError(
          "Mobile number should be atleast 5 and not more than 15 digits"
        );
        return;
      }

      // setSelectedFiledType("mobile"); // Otherwise, stay on mobile input

      setEmailError("");
      setMobileError("");

      // if (!value) {
      //   setSelectedFiledType("email");
      // } else {
      //   setSelectedFiledType("mobile");
      // }

      if (textForCode == "Resend Code") {
        setTextForCode("Get Code");
      }

      // setMobile(value);
      // setCountryCode("+" + country.dialCode);
      // setMobileError("");
    }

    if (name === "countryCode") {
      if (!value || value.length < 2) {
        setCountryCode("+");
        setMobileError("Country code is required");
        return;
      }

      setCountryCode(value);

      const regex = /^\+\d{1,4}$/;

      if (!regex.test(value)) {
        // setCountryCode("+");
        setMobileError("Please enter a valid country code");
        return;
      }

      setMobileError("");
    }
  };

  const handleCheckStatus = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let result = await checkKycStatus({}, config);
    if (result.status) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
  };

  return (
    <>
      <div className="personal-outer">
        <div className="row align-items-top">

          <div className="col-md-12 col-12">

            {(userdata?.kycVerification_status == "Y") ?

              <div className="white_box">
                <h6 className="position-relative">
                  {t("Your Kyc is completed")}
                </h6>
              </div>
              : (userdata?.kycVerification_status == "N") ?
                <>
                  <div className="white_box personal_box" onLoad={initKYC}>
                    <div id="debug_win" className="debug_win" style={{ display: "none" }}></div>

                    <div id="customer_start_ui" className="fullscreen customerUI" style={{ "textAlign": "center", display: (userInfo.showIframe) ? "none" : "block" }}
                    >
                      <div className="custom--header" style={{ " background": "no-repeat url('./img/bg_graphic.svg')", "backgroundSize": "cover" }}>
                        KYC
                      </div>
                      <div className="custom--section">
                        <h3 className="custom--headline">개인정보 옵션</h3>
                        <div className="flex custom--radio-box" id="use_input_ui" style={{ "marginBottom": "16px" }}
                          onClick={() => selectPrivacy("use_input_ui")}>
                          <div className={"custom--radio-check " + (userInfo.type == "use_input_ui" ? "checked" : "")} style={{ "marginRight": "18px" }}>
                            <img src="./img/circle.svg" alt="check" />
                          </div>
                          <div className="flex flex-col" style={{ "textAlign": "start" }}>
                            <span className="custom--title" style={{ "marginBottom": "8px" }}>
                              필수정보 입력화면 사용
                            </span>
                            <span className="custom--label">
                              사용자 필수정보 입력을 useB.eKYC에 위임
                            </span>
                          </div>
                        </div>
                        <div className="flex custom--radio-box" id="param" onClick={() => selectPrivacy("params")}>
                          <div className={"custom--radio-check " + (userInfo.type == "params" ? "checked" : "")} style={{ "marginRight": "18px" }}>
                            <img src="./img/circle.svg" alt="check" />
                          </div>
                          <div className="flex flex-col" style={{ "textAlign": "start" }}>
                            <span className="custom--title" style={{ "marginBottom": "8px" }}>
                              필수정보 입력화면 미사용
                            </span>
                            <span className="custom--label">
                              고객사에서 useB.eKYC로 사용자 필수정보를 parameter로 전달
                            </span>
                          </div>
                        </div>

                        <div></div>
                      </div>
                      <select name="userinfo_type" id="userinfo_type" style={{ "display": "none" }}>
                        <option value="param">
                          입력 UI 미제공(사용자 정보를 고객사에서 처리후 parameter로 전달)
                        </option>
                        <option value="use_input_ui">
                          입력 UI 제공(사용자 정보 입력 처리를 useb.eKYC에 위임)
                        </option>
                      </select>
                      <div className="custom--division" id="userinfo-division" style={(userInfo.type != "params") ? { display: "none" } : {}}></div>

                      <div id="userinfo_div" className="custom--section" style={(userInfo.type != "params") ? { display: "none" } : {}}>
                        <h3 className="custom--title" style={{ "fontSize": "20px", "marginTop": "0px" }}>
                          테스트할 신분증 정보를 입력해주세요.
                        </h3>
                        <h6 className="custom--label" style={{ "fontSize": "14px", "lineHeight": "24px" }}>
                          실제 프로세스에서 해당 단계는 고객사 내부에서 관리하는 사용자
                          필수정보를 parameter로 전달하게 됩니다.
                        </h6>
                        <div className="flex flex-col text-start">
                          <span className="custom--label" style={{ "marginBottom": "8px" }}>이름</span>
                          <input
                            id="userinfo_name"
                            type="text"
                            placeholder="홍길동 or GILDONG HONG"
                            className="input_txt_kr custom--input"
                            onChange={(e) => handler(e)}
                            name="name"
                          />
                          <span
                            className="custom--label"
                            style={{ "marginTop": "8px", "lineHeight": "120%", "fontSize": "0.6rem" }}
                          >
                            &nbsp; * 외국인의 경우 <b>Lastname Firstname Middlename</b>으로
                            입력하세요.
                          </span>
                        </div>
                        <br />
                        <div className="flex flex-col text-start">
                          <span className="custom--label" style={{ "marginBottom": "8px" }}>
                            생년월일
                          </span>
                          <input
                            ref={myRef}
                            id="userinfo_birthday"
                            type="text"
                            placeholder="YYYY-MM-DD"
                            maxLength="10"
                            nextfocus="userinfo_phone_number"
                            className="custom--input"
                            onChange={(e) => handler(e)}
                            name="birthday"
                          />
                        </div>
                        <br />
                        <div className="flex flex-col text-start">
                          <span className="custom--label" style={{ "marginBottom": "8px" }}>
                            전화번호
                          </span>
                          <input
                            id="userinfo_phone_number"
                            type="number"
                            placeholder="01012345678"
                            maxLength="11"
                            className="custom--input"
                            onChange={(e) => handler(e)}
                            name="phone_number"

                          />
                        </div>
                        <br />
                        <div className="flex flex-col text-start">
                          <span className="custom--label" style={{ "marginBottom": "8px" }}> 이메일 </span>
                          <input
                            id="userinfo_email"
                            type="email"
                            placeholder="email.id@domain.net"
                            className="custom--input"
                            onChange={(e) => handler(e)}
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="custom--division"></div>

                      <div className="custom--section" id="logic-options">
                        <h3 className="custom--headline">기능 선택 옵션</h3>
                        <ul>
                          <li>
                            <img
                              onClick={() => buttonOnClick(105)}
                              src="img\id_face.svg"
                              className="imgBtn"
                            /><br />
                          </li>
                        </ul>
                      </div>

                      {/* <div className="custom--division"></div>

                <div className="custom--section">
                  <h3 className="custom--headline">신분증 인증 Mode</h3>
                  <h6 className="custom--label" style={{ "fontSize": "14px", "lineHeight": "24px" }}>
                    1. Server OCR vs WebAssembly OCR 2. 사본탐지 OFF vs 사본탐지 ON ※ 사본
                    탐지는 WASM OCR 사용시만 동작
                  </h6>
                  <div className="custom--label flex justify-center align-center gap20">
                    <label htmlFor="wasm_ocr_mode_checkbox">Server OCR</label>
                    <label className="switch">
                      <input id="wasm_ocr_mode_checkbox" type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                    <label htmlFor="wasm_ocr_mode_checkbox">Wasm OCR</label>
                  </div>
                  <br />
                  <div className="custom--label flex justify-center align-center gap20">
                    <label htmlFor="wasm_ssa_mode_checkbox">사본탐지 OFF</label>
                    <label className="switch">
                      <input id="wasm_ssa_mode_checkbox" type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                    <label htmlFor="wasm_ssa_mode_checkbox">사본탐지 ON</label>
                  </div>
                </div> */}

                      {/* <div className="custom--division"></div>

                <div className="custom--section">
                  <h3 className="custom--headline">영문(English)화 설정</h3>
                  <h6 className="custom--label" style={{ "fontSize": "14px", "lineHeight": "24px" }}>
                    활성화시 영문(English)으로 KYC 진행이 가능합니다.
                  </h6>
                  <label className="switch">
                    <input id="is_english_checkbox" type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div> */}

                      {/* <div className="custom--division"></div>

                <div className="custom--section">
                  <h3 className="custom--headline">Debug Window 설정</h3>
                  <h6 className="custom--label" style={{ "fontSize": "14px", "lineHeight": "24px" }}>
                    활성화하면 PostMessage를 수신하는 시점과 수신 내용을 Debug Window를
                    통해 보여줍니다.
                  </h6>
                  <label className="switch">
                    <input id="debug_win_checkbox" type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div> */}

                      <div className="custom--division"></div>

                      <div className="custom--section">
                        <h3 className="custom--headline">인증 요청 타입</h3>
                        <h6 className="custom--label" style={{ "fontSize": "14px", "lineHeight": "24px" }}>
                          활성화하면 인증 요청을 access_token 방식으로 요청합니다.
                        </h6>
                        <div className="custom--label flex justify-center align-center gap20">
                          <label htmlFor="auth_type_checkbox">Credential</label>
                          <label className="switch">
                            <input id="auth_type_checkbox" type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          <label htmlFor="auth_type_checkbox">Access Token</label>
                        </div>
                      </div>

                      {/* <div className="custom--division"></div>

                <div className="custom--section">
                  <h3 className="custom--headline">Font 커스텀 설정</h3>
                  <h6 className="custom--label" style={{ "fontSize": "14px", "lineHeight": "24px" }}>
                    직접입력(유료폰트 등)의 경우 사전의 협의 후 설정 가능 <br />
                    ※ 기본 적용 폰트 : Noto Sans KR
                  </h6>

                  <div className="flex flex-col text-start">
                    <span className="custom--label" style={{ "marginBottom": "8px" }}>폰트선택</span>

                    <select
                      name="custom_font_select"
                      id="custom_font_select"
                      className="custom--input"
                    >
                      <option value="Noto Sans KR">Noto Sans KR (디폴트)</option>
                      <option value="Noto Serif KR">Noto Serif KR</option>
                      <option value="Nanum Gothic">Nanum Gothic</option>
                      <option value="etc">직접입력</option>
                    </select>
                  </div>

                  <br />
                  <br />

                  <div
                    id="custom_font_etc"
                    className="flex flex-col text-start"
                    style={{ "display": "none", width: "100%" }}
                  >
                    <span className="custom--label" style={{ "marginBottom": "8px" }}>
                      직접입력
                    </span>
                    <input
                      id="custom_font"
                      type="text"
                      placeholder="폰트 이름"
                      className="custom--input"
                    />
                  </div>
                </div> */}
                    </div>

                    <div id="kyc" className="fullscreen" style={!userInfo.showIframe ? { "display": "none" } : { "display": "block", height: "800px" }}>
                      <iframe id="kyc_iframe" className="fullscreen" allow="camera"></iframe>
                    </div>

                    <div
                      id="customer_end_ui"
                      className="fullscreen customerUI"
                      style={{ "display": "none" }}
                    >
                      <div
                        className="custom--header"
                        style={{ " background": "no-repeat url('./img/bg_graphic.svg')", "backgroundSize": "cover" }}
                      >
                        eKYC Test Result
                      </div>

                      <div className="custom--section">
                        <div id="kyc_status" className="custom--headline"></div>
                      </div>

                      <div className="custom--section">
                        <button className="custom--btn" onClick={() => initKYC()}>
                          처음부터 다시하기
                        </button>
                      </div>

                      <div className="custom--division"></div>

                      <div id="kyc_result" className="custom--section"></div>
                    </div>

                    <div
                      id="loading_ui"
                      className="fullscreen"
                      style={{
                        "display": "none", "background": "rgba(0, 0, 0, 0.5)", "justifyContent": "center",
                        "alignItems": "center", "fontSize": "2rem", "color": "white"
                      }}

                    >
                      LOADING ....
                    </div>


                  </div>
                </> : ""


            }
          </div>

          {/* <div className="col-md-12 col-12">
            <div className="white_box">
              <h6 className="position-relative">
                complete Your Kyc
              </h6>
              <iframe id="db_kyc_iframe" src={`https://kyc.dbiexchange.com/?id=${localStorage.getItem("id")}`} width="100%" height={"800px"} className="iframe-background " allow="camera"   title="myFrame"/>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default Personal;
