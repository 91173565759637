// const mainUrl = "http://192.168.1.32:5003/frontapi",
const mainUrl = "https://api.dbiexchange.com/frontapi",
//const mainUrl = "https://apiuat.dbiexchange.com/v1/frontapi",

  base = {
    whitelistData: "/whitelistData",
    deleteWhitelist: "/deleteWhiteAddress",
    whitelistAdd: "/whitelist",
    cryptoCoinList: "/cryptocoin_list",
    userData: "/UserData",
    deleteWhiteList: "/deleteWhiteAddress",
    coinPairList: "/coin_pair_list",
  };

export { base, mainUrl };
