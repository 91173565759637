import React, { useEffect, useState } from "react"
import axios from "axios";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../Common/BaseUrl";
import Header from './HomepageElements/Header'
import Table from "./HomepageElements/Table"
import Testimonial from "./HomepageElements/Testimonial"
import Appss from "./HomepageElements/Appss"
import Features from "./HomepageElements/Features"
import NeedHelp from "./HomepageElements/NeedHelp"
import MarketNews from "./HomepageElements/MarketNews"
import Banners from "./HomepageElements/Banners"
import Blogs from "./HomepageElements/Blogs"
import Trade from "./HomepageElements/Trade"
import Footer from '../Common/Footer'
import Nav from "../Common/Nav"


const Index = () => {
  const { i18n, t } = useTranslation();
  const [state, setState] = useState([]);
  const [setData, setFrontData] = useState([]);

  useEffect(() => {
    getData();
    getFrontData();
  }, [i18n.language])

  const getData = () => {
    let lang = localStorage.getItem("lang");
    const config = {
      headers: {
        lang: lang,
        // Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    console.log(config);
    axios.get(baseUrl + "/frontapi/get-blog", config).then((res) => {
      if (res.data.status) {
        setState(res.data.data)
      }
    }).catch((err) => {

    })
  }

  const getFrontData = () => {
    let lang = localStorage.getItem("lang");
    const config = {
      headers: {
        lang: lang,
        // Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    console.log(config);
    axios.get(baseUrl + "/frontapi/get_front_text", config).then((res) => {
      console.log('get_front_text======>',res)
      if (res.status) {
        setFrontData(res.data)
      }
    }).catch((err) => {

    })
  }

  return (
    <div>

      <Nav />
      <Header frontData={setData}/>
      <Banners />
      <Table frontData={setData}/>
      <Blogs stocksName={state} />
      <Testimonial />
      <NeedHelp frontData={setData}/>
      <Features />
      <Appss frontData={setData}/>
      {/* <MarketNews /> */}
      <Footer />
    </div>
  )
}

export default Index;
