import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl, imgUrl } from "../../Common/BaseUrl";

const Blogs = (props) => {
   // console.log(props.stocksName);
    const { t } = useTranslation();
    const [state, setState] = useState({});
    const [popUpModal, setpopUpModal] = useState(false);
    const [bannerTextList, setBannerTextList] = useState([]);

    useEffect(() => {
        
    }, [])

    const closeImage = () =>{
        setpopUpModal(false)
    }

    const closePopUpModel = () =>{
        setpopUpModal(false)
    }

    const showModal = (val) =>{
        console.log(val);
        setpopUpModal(true)
        setState(val)
    }

    return (
        <div className="how-main-outer text-center pt-5">
            <div className="container">
                <div className="section-heading mb-md-4">
                    <h2>{t("Market News")}</h2>
                </div>
                <div className="how-outer pb-4">
                    <div className="row">
                        <div className="col-md-12 col-12" >
                            <div className="blog-list-outer">
                                <ul>
                                    {(props.stocksName) ? props.stocksName.map((val, index) =>
                                        <li key={index}>
                                                <a href={val.link} className="a_flex" target="_blank">   
                                            <img src={imgUrl+"/static/blog/" + val.image} alt="" className="img-fluid" />
                                            </a>
                                            <span>{val.title}</span>
                                            <div><button className="btn read_more" onClick={()=>showModal(val)}>{t("Read More")}</button></div>
                                            </li>
                                    ) : ""}
                                </ul>
                            </div>
                        </div>                  
                    </div>
                </div>
            </div>
            <Modal
            size="lg"
            fullscreen={true}
            show={popUpModal}
            onHide={closePopUpModel}
            keyboard={false}
          >
            {/* <Modal.Header closeButton>

                        </Modal.Header> */}
            <Modal.Body>
              
                 <div className="image_popup" >
                  <p><span style={{ fontSize: "20px" }}>{state.title} </span><i className="fa fa-close" style={{ float: "right", margin: "15px", cursor: "pointer" }} onClick={(e) => closeImage()}></i></p>

                        
                  <img
                    className=""
                    src={imgUrl + "/static/blog/" + state.image}
                    alt="coin"
                    style={{ width: "100%" }}
                  />
                   
                   <div dangerouslySetInnerHTML={{ __html: state.description }} />
                  {/* <p><span style={{ fontSize: "15px" }}>{state.description} </span></p> */}
                </div> 
            </Modal.Body>
          </Modal>
        </div>
    )
}

export default Blogs